import { memo } from 'react'
import type { FC } from 'react'
import type { NextPage } from 'next'
import { useTranslator } from '@/hooks'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { fetchAPI } from '@/api'
import { Layout, Subscribe } from '../components'
import { Button, HeaderMarquee } from '@dy/commons/components'
import { vw, mq, getP26_1, getP18_1 } from '@dy/commons/styles'
import { HeadTag } from '@dy/commons/components'
import { TRANSLATIONS } from './_error'
import { getLang } from '@dy/commons/utils'
export interface ErrorProps {
  error?: any,
}

const Main = styled.main`
  overflow-x: hidden;
  padding: 0;
  position: relative;

  ${mq.greaterThan('tablet')`
    overflow-x: inherit;
  `}

`

const Section = styled.section`
  grid-column: 1 / span 6;
  padding: ${vw(50, 'mobile')} ${vw(20, 'mobile')};
  align-items: center;
  margin-bottom:${vw(50, 'mobile')} ;

  ${mq.greaterThan('tablet')`
    grid-column: 5 / span 4;
    padding: ${vw(60, 'desktop')} 0;
    margin-bottom:${vw(60, 'desktop')} ;
  `}

  button {
    color: ${({ theme }) => theme.colors.white};
    margin-top: ${vw(60, 'mobile')};

    ${mq.greaterThan('tablet')`
      margin-top:${vw(30, 'desktop')} ;
    `}
  }

  h2, p {
    font-family: ${({ theme }) => theme.fonts.secondaryFont};
    text-align: center;
    padding: 15px;
  }

  h2 {
    ${getP26_1()}
    color: ${({ theme }) => theme.colors.red};
    font-weight: 700 !important;
  }

  p {
    ${getP18_1()}
    color: ${({ theme }) => theme.colors.darkred};
  }

`

export async function getStaticProps() {
  const [layout, layoutError] = await fetchAPI('layout')
  if(layoutError) return { notFound: true }

  return {
    props: {
      layout
    }
  }
}

const NotFoundPage: NextPage<ErrorProps> & { Layout?:FC } = memo(() => {
  const { push, locale:localeRouter } = useRouter() || { push: () => {} }
  const locale = getLang(localeRouter)
  const { t } = useTranslator()

  const onClick = () => {
    push('/')
  }

  return (
    <>
      <HeadTag seo={TRANSLATIONS[locale]} />
      <HeaderMarquee data={t('commons.page_not_found.404')} />
      <Main>
        <Section>
          <h2>{t('commons.page_not_found.title')}</h2>
          <p>{t('commons.page_not_found.description')}</p>
          <Button onClick={onClick}>{t('commons.page_not_found.goback_homepage')}</Button>
        </Section>
        <Subscribe/>
      </Main>
    </>
  )
})

NotFoundPage.Layout = Layout
export default NotFoundPage
